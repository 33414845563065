import React from "react";
import "./index.css";
// import ModalButton from "../Button/button";
import Effect from "../RippleEffect";

const OrderCard = ({ code, items, price, time, location }) => {
  return (
    <>
      <div className="card-top d-flex justify-content-between">
        <p className="h5">Order {code}</p>
        <div className="h6 d-flex align-items-center">
          <Effect />
          {time}
        </div>
      </div>
      {
        // items.map((item, index) => <p className="h4" key={index}>{item.quantity}x {item.name}</p>)
      }
      <div className="d-flex justify-content-between mt-5">
        <span className="h5">{location}</span>
        <p className="h5">{price}</p>
      </div>
    </>
  );
};

export default OrderCard;
