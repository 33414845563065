import React, { useState } from "react";
import OrderCard from "../orders/index";
import CardButton from "../Button/cardbutton";
import MainModal from "../Modals/MainModal/index";
import Global from "../../model/Global";

const Compeletd = (order) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  
  const handleShow = () => {
    const editedOrder = JSON.parse(JSON.stringify(order));
    editedOrder.selected_btn = {
      name: '',
      color: '',
      hidden: 'none'
    };
    Global.selectedOrder = editedOrder;
    setShow(true);
  };
  
  return (
    <div className="card">
      <OrderCard
        code={"#" + order.id}
        item={order.selected_types}
        price={order.currency + ' ' + order.total_price}
        time="12 mins ago"
      />
      <div className="card-button d-flex justify-content-between">
        <CardButton type="view" text="view order" open={handleShow} />
        <MainModal handleClose={handleClose} show={show} order={order} />
      </div>
    </div>
  );
};

export default Compeletd;
