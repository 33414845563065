import React from "react";
// import styled from "@emotion/styled";
import { Form, Button } from "react-bootstrap";
import "./loginform.css";
import Global from "../../model/Global";

const LoginForm = () => {

  const credentials = {
    username: 'florentine_daniyal',
    password: 'florentine123@'
  }

  const login = () => {

    const params = {
      username: credentials.username,
      password: credentials.password
    };

    fetch(Global.apiURL+'user/login', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(params),
      })
      .then(response => response.json())
      .then(data => {
        console.log( data );

        if ('error' in data) {
          alert('Wrong credentials!');
          return;
        }

        window.location.href = '/home';

    });
  }

  return (
    <Form className="form">
      <div className="text-center">
        <p className="h3">Login To Continue</p>
      </div>
      <Form.Group controlId="formBasicUsername">
        <Form.Label>Username</Form.Label>
        <Form.Control type="text" placeholder="Enter Username" value='florentine_daniyal' onChange={e => {
          credentials.username = e.target.value;
        }} />
      </Form.Group>

      <Form.Group controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control type="password" placeholder="Password" value='florentine123@' onChange={e => {
          credentials.password = e.target.value;
        }} />
      </Form.Group>
      <Button className="button" type="button" block onClick={login}>
          SUMBIT
      </Button>
    </Form>
  );
};

export default LoginForm;
