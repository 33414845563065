import React from "react";
import Orders from "../components/Sections/index";
import "../App.css";
import Topbar from "../components/Topbar/index";
import Global from "../model/Global";

const Home = () => {

  const fetchWaiterCallData = () => {

    fetch(Global.apiURL+'brand/1/branch/1/waiter/calls')
      .then(response => response.json())
      .then(data => {
        console.log( data )
        Global.data.call_waiter = data
      });

  }

  const fetchBarData = () => {

    fetch(Global.apiURL+'branch/1')
      .then(response => response.json())
      .then(data => {

        console.log( data )

        Global.data = {
          orders_new: [],
          orders_inprocess: [],
          orders_completed: [],
          orders_cancelled: []
        }

        Global.data.logo = data.image;

        data.orders.forEach(order => {
          let orderTotal = 0;

          order.id = order.id.toString().padStart(6, '0');

            order.items.forEach(item => {
                orderTotal += item.price;
            });

            order.total_price = orderTotal.toFixed(2);
            order.currency = data.brand.currency;

            if ( order.status.name === 'Pending') {
              Global.data.orders_new.push(order);
            } else if ( order.status.name === 'Cooking') {
              Global.data.orders_inprocess.push(order);
            } else if ( order.status.name === 'Served') {
              Global.data.orders_completed.push(order);
            } else if ( order.status.name === 'Cancelled') {
              Global.data.orders_cancelled.push(order);
            }
        });

      });

  }

  fetchBarData();
  fetchWaiterCallData();

  setInterval(() => {
    fetchBarData();
    fetchWaiterCallData();
  }, 10000);

  return (
    <>
      {/* <Topbar /> */}
      <Orders />
    </>
  );
};

export default Home;
