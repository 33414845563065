import React, { useState } from "react";
import NewOrder from "./newoder";
import InProcess from "./inprocess";
// import ReadyToServe from "./readytoserve";
import Compeletd from "./completed";
import Cancelled from "./cancelled";
import "./section.css";
import Global from "../../model/Global";
import MainModal from "../Modals/MainModal/index";
import CallWaiter from "../Modals/callwaiter";

import mySound from '../../audios/incoming_order.wav';

const Orders = () => {

  const [orders_new, setOrdersNew] = useState([]);
  const [orders_inprocess, setOrdersInProcess] = useState([]);
  // const [orders_readytoserve, setOrdersReadyToServe] = useState([]);
  const [orders_completed, setOrdersCompleted] = useState([]);
  const [orders_cancelled, setOrdersCancelled] = useState([]);
  const [call_waiter, setCallWaiter] = useState([]);
  const [show, setShow] = useState(false);
  const [showCallWaiter, setShowCallWaiter] = useState(false);

  let audio_count = 0;

  const handleCallWaiter = () => {
    console.log(Global.data.call_waiter)
    if (Global.data.call_waiter) {
      if ( call_waiter && Global.data.call_waiter.length > 0 ) {
        setShowCallWaiter(true);
        Global.updateOrders = false;
      }
    }
  }

  const handleCloseCallWaiter = (id) => {
    if ( Global.data.call_waiter ) {
      Global.data.call_waiter.shift()
    }
    setShowCallWaiter(false)
    Global.updateOrders = true;

    fetch(Global.apiURL+'waiter/call/'+id+'/seen', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({})
    })
    .then(response => response.json())
    .then(data => {
      // alert(data);
    });
  }

  const handleClose = (del) => {
    setShow(false);
    setTimeout(() => {
      if (del) {
        Global.prevOrders--;
      }
      Global.newOrdersQueue.splice(0,1);
      if ( Global.newOrdersQueue.length > 0 ) {
        setTimeout(() => {
          setShow(true);
          Global.updateOrders = false;
        }, 500);
      } else {
        Global.showOnce = true;
      }
      Global.updateOrders = true;
    }, 200);
  };

  const handleNewOrders = () => {
    if (Global.data.orders_new.length > Global.prevOrders) {
      const newOrdersCount = Global.data.orders_new.length - Global.prevOrders;

      for (let i = 0; i < newOrdersCount; i++) {
        const newOrder = Global.data.orders_new[Global.prevOrders+i];

        Global.newOrdersQueue.push(newOrder);
      }

      Global.prevOrders = Global.data.orders_new.length;
    }
  };

  const showNewOrders = () => {
    if ( Global.newOrdersQueue.length > 0 && Global.showOnce ) {
      const editedOrder = JSON.parse(JSON.stringify(Global.newOrdersQueue[0]));
      editedOrder.selected_btn = {
        name: 'Accept Order',
        color: 'modal-red',
        hidden: ''
      };
      Global.selectedOrder = editedOrder;
      Global.showOnce = false;
      setShow(true);
      Global.updateOrders = false;
      
      const audio = new Audio(mySound);
      audio.play();

      audio.addEventListener('ended', () => {
        if ( audio_count < 3 ) {
          audio_count++;
          audio.play();
        } else {
          audio_count = 0;
        }
      },false);

    }
  };

  setInterval(() => {

    setOrdersNew( Global.data.orders_new );
    setOrdersInProcess( Global.data.orders_inprocess );
    // setOrdersReadyToServe( Global.data.orders_readytoserve );
    setOrdersCompleted( Global.data.orders_completed );
    setOrdersCancelled( Global.data.orders_cancelled );
    setCallWaiter( Global.data.call_waiter );
    
    if ( Global.updateOrders ) {
      handleNewOrders();
      showNewOrders();
      handleCallWaiter();
    }
    
  }, 2000);
  

  return (
    <div className="top">
      {
        Global.newOrdersQueue.length>0 && !Global.showOnce ? <MainModal handleClose={handleClose} show={show} order={Global.newOrdersQueue[0]} /> : null
      }
      <CallWaiter
        handleClose={handleCloseCallWaiter}
        show={showCallWaiter}
        tableno={call_waiter ? call_waiter[0]?.table_no : null}
        msg={call_waiter ? call_waiter[0]?.msg : null}
        reqtime={call_waiter ? call_waiter[0]?.created_at : null}
        id={call_waiter ? call_waiter[0]?.id : null}
      />
      <section className="color-bg">
        <div className="container-fluid ">
          <div className="row orders-row mt-3">
            <div className="col-12 col-lg-3 col-md-6 ">
              <div className="red">
                <h3>New Orders</h3>
                <div className="cover">
                  {
                    orders_new.map((order, index) => {
                      return <NewOrder key = { index } { ...order } />
                    })
                  }
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-3 col-md-6">
              <div className="yellow">
                <h3>Cooking</h3>
                <div className="cover">
                  {
                    orders_inprocess.map((order, index) => {
                      return <InProcess key = { index } { ...order } />
                    })
                  }
                </div>
              </div>
            </div>
            {/* <div className="col-12 col-lg-3 col-md-6">
              <div className="blue">
                <h3>Ready To Serve</h3>
                <div className="cover">
                  {
                    orders_readytoserve.map((order, index) => {
                      return <ReadyToServe key = { index } { ...order } />
                    })
                  }
                </div>
              </div>
            </div> */}
            <div className="col-12 col-lg-3 col-md-6">
              <div className="green">
                <h3>Served</h3>
                <div className="cover">
                  {
                    orders_completed.map((order, index) => {
                      return <Compeletd key = { index } { ...order } />
                    })
                  }
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-3 col-md-6">
              <div className="gray">
                <h3>Cancelled</h3>
                <div className="cover">
                  {
                    orders_cancelled.map((order, index) => {
                      return <Cancelled key = { index } { ...order } />
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Orders;