import React from "react";
import "./style.css";

const Effect = () => {
  return (
    <div className="preloader">
      <span></span>
      <span></span>
    </div>
  );
};

export default Effect;
