import React from "react";
import Global from "../../../model/Global";

const TotalTable = () => {

  let totalItems = 0;

  Global.selectedOrder.items.forEach(item => {
    totalItems += item.quantity;
  });

  return (
    <div className="totle-table mt-3">
      <div className="totle-table1">
        <p className="h3 mb-3">Total</p>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex ">
            <p className="mr-2 h5">Subtotal</p>
            <span>{totalItems} items</span>
          </div>
          <p className="h6">{Global.selectedOrder.items[0].currency}{Global.selectedOrder.total_price}</p>
        </div>
      </div>
      <div className="d-flex justify-content-between totle-table2 align-items-center">
        <div className="d-flex ">
          <p className="mr-2 h5">Tax</p>
          <span>(incl)</span>
        </div>
        <p className="h6">{Global.selectedOrder.items[0].currency}0.15</p>
      </div>

      <div className="d-flex justify-content-between totle-table2">
        <p className="mr-2 h5">Order Total</p>
        <p className="h5">{Global.selectedOrder.items[0].currency}{(parseFloat(Global.selectedOrder.total_price) + 0.15).toFixed(2)}</p>
      </div>
    </div>
  );
};

export default TotalTable;
