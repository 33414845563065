import React, { useState } from "react";
import OrderCard from "../orders/index";
import CardButton from "../Button/cardbutton";
import Global from "../../model/Global";
import MainModal from "../Modals/MainModal/index";

const NewOrder = (order) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  
  const handleShow = () => {
    const editedOrder = JSON.parse(JSON.stringify(order));
    editedOrder.selected_btn = {
      name: 'Accept Order',
      color: 'modal-red',
      hidden: ''
    };
    Global.selectedOrder = editedOrder;
    setShow(true);
  };

  const orderAccepted = () => {
    Global.data.orders_new.forEach((item, index) => {
      if ( item.id === order.id ) {
        let globalData = JSON.parse(JSON.stringify(Global.data));
        globalData.orders_new.splice(index, 1);
        Global.data = globalData;
        Global.prevOrders--;
      }
    });

    const params = {
      order_id: parseInt(order.id),
      status_id: order.status.id + 1
    };

    fetch(Global.apiURL+'order/update', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(params),
      })
      .then(response => response.json())
      .then(data => {
        //alert(data.msg);
    });
  };

  return (
    <div className="card">
      <OrderCard
        code={"#" + order.id}
        item={order.selected_types}
        price={order.currency + ' ' + order.total_price}
        time="12 mins ago"
      />
      <div className="card-button d-flex justify-content-between">
        <CardButton type="view" text="view order" open={handleShow} />
        <CardButton type="accept" text="Accept" open={orderAccepted} />
        <MainModal handleClose={handleClose} show={show} order={order} />
      </div>
    </div>
  );
};

export default NewOrder;
