import React from "react";

const OrderDetails = ({ code, pick, date, mode, time }) => {
  return (
    <>
      <>
        <div className="card-top order-details">
          <p className="h3">Order {code}</p>
          <div style={{display: 'flex'}}>
            <p className="h5">Table #{pick}</p>
            <p className="h5 ml-3">Pay by: {mode}</p>
          </div>
        </div>
        <span>Order at {date}</span>
      </>
    </>
  );
};

export default OrderDetails;
