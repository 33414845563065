import React from "react";

const OrderTable = (item) => {

  const modifiers = JSON.parse(item.modifiers);

  return (
    <div className="ordertable mb-2">
      <div className="mb-1">
        <p className="h5">{item.quantity}x {item.name}</p>
      </div>

      {
        modifiers.length > 0 ? <p className="h5">Modifiers</p> : null
      }

      {
        modifiers.map((modifier, index) => {
          return <div key={index} className="ice d-flex">
            <p className="h5 mr-1">{modifier.category.name} - </p>
            {
              <span key={0}>&nbsp;{modifier.modifier.name},</span>
              // modifier.modifier.map((mod, ind) => {
              //   return <span key={ind}>&nbsp;{mod.name},</span>
              // })
            }
          </div>
        })
      }

    </div>
  );
};

export default OrderTable;
