import React from "react";
import LoginForm from "../components/LoginForm/loginfrom";
import "./login.css";

const Login = () => {
  return (
    <div className="container-fluid bg">
      <div className="row ">
        <div className="col-12 col-md-5">
          <LoginForm />
        </div>
      </div>
    </div>
  );
};

export default Login;
