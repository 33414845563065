import React from "react";
import OrderDetails from "./orderdetails";
import { Modal } from "react-bootstrap";
import ModalButton from "../../Button/modalbuton";
import OrderTable from "./ordertable";
import Effect from "../../RippleEffect";
import TotalTable from "./totaltable";
import Global from "../../../model/Global";

const MainModal = ({ handleClose, show, order }) => {

  const onOrderCancel = () => {
    
    if ( order.status.name === 'Pending' ) {
      Global.data.orders_new.forEach((item, index) => {
        if ( item.id === order.id ) {
          let globalData = JSON.parse(JSON.stringify(Global.data));
          globalData.orders_new.splice(index, 1);
          Global.data = globalData;
        }
      });
    } else if ( order.status.name === 'Cooking' ) {
      Global.data.orders_inprocess.forEach((item, index) => {
        if ( item.id === order.id ) {
          let globalData = JSON.parse(JSON.stringify(Global.data));
          globalData.orders_inprocess.splice(index, 1);
          Global.data = globalData;
        }
      });
    }

    const params = {
      order_id: parseInt(order.id),
      status_id: 4
    };

    fetch(Global.apiURL+'order/update', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
      })
      .then(response => response.json())
      .then(data => {
        //alert(data.msg);
        handleClose(true);
    });
  };

  const onOrderAccept = () => {
    if ( order.status.name === 'Pending' ) {
      Global.data.orders_new.forEach((item, index) => {
        if ( item.id === order.id ) {
          let globalData = JSON.parse(JSON.stringify(Global.data));
          globalData.orders_new.splice(index, 1);
          Global.data = globalData;
        }
      });
    } else if ( order.status.name === 'Cooking' ) {
      Global.data.orders_inprocess.forEach((item, index) => {
        if ( item.id === order.id ) {
          let globalData = JSON.parse(JSON.stringify(Global.data));
          globalData.orders_inprocess.splice(index, 1);
          Global.data = globalData;
        }
      });
    }

    const params = {
      order_id: parseInt(order.id),
      status_id: order.status.id + 1
    };

    fetch(Global.apiURL+'order/update', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
      })
      .then(response => response.json())
      .then(data => {
        //alert(data.msg);
        handleClose(true);
    });
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Body>
        <div className="row">
          <div className="col">
            <div className="col-12">
              <OrderDetails
                code={"#"+order.id}
                pick={order.order_location}
                date={order.created_at}
                mode={order.payment_mode}
              />
            </div>
            <div className="row order-details-title">
              <div className="col-lg-12 col-12">
                <div className="d-flex justify-content-between align-items-center ">
                  <p className="h3 ">{order.status.name}</p>
                  <div className="h6 d-flex align-items-center">
                    <Effect />31 mins ago
                  </div>
                </div>
                <div className="col-lg-12 col-12 mt-5">
                  <TotalTable />
                </div>
              </div>
            </div>
          </div>
          <div className="col modal-row col-lg-6 col-6">
            {
                order.items.map((item, index) => {
                  return <div key={index} className="col-lg-12 col-12 order-table-1">
                    <OrderTable {...item} />
                  </div>
                })
              }
          </div>
        </div>
        
        <div className="text-center d-flex justify-content-center">
          <ModalButton
            text={Global.selectedOrder ? Global.selectedOrder.selected_btn.name : ''}
            type={Global.selectedOrder ? Global.selectedOrder.selected_btn.color : ''}
            Close={onOrderAccept}
            visibility={Global.selectedOrder ? Global.selectedOrder.selected_btn.hidden : ''}
          />
          <ModalButton
          text="Cancel Order"
          type="modal-red"
          Close={onOrderCancel}
          visibility={Global.selectedOrder ? Global.selectedOrder.selected_btn.hidden : ''} />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MainModal;
