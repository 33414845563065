import React from "react";
import "./button.css";

const CardButton = ({ text, type, open }) => {
  const className = `button ${type}`;
  return (
    <button className={className} onClick={open}>
      {text}
    </button>
  );
};

export default CardButton;
