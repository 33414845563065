import React from "react";
import Login from "./pages/login";
import Home from "./pages/home";

import { Router } from "@reach/router";

export const App = () => {
  return (
    <Router>
      <Login path="/" />
      <Home path="/home" />
    </Router>
  );
};

export default App;
