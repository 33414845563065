export default class Global {

    // static baseUrl = 'http://127.0.0.1:8000/';
    static baseUrl = 'https://admin.oderupp.co/public/';

    static apiVersion = 'api/v1/';
    static apiURL = Global.baseUrl + Global.apiVersion;

    static data = {
        logo: '',
        orders_new: [],
        orders_inprocess: [],
        orders_completed: [],
        orders_cancelled: [],
        call_waiter: []
    };

    static selectedOrder = null;
    static prevOrders = 0;
    static showOnce = true;
    static newOrdersQueue = [];
    static updateOrders = true;

}