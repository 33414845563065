import React from "react";
import "./button.css";

const ModalButton = ({ text, type, Close, visibility }) => {
  const className = `button ${type}`;
  return (
    <button className={className} onClick={Close} style={{display: visibility}}>
      {text}
    </button>
  );
};

export default ModalButton;
