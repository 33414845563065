import React from "react";
import { Modal } from "react-bootstrap";
import "./modal.css";
import ModalButton from "../Button/modalbuton";

const CallWaiter = ({ show, handleClose, tableno, msg, reqtime, id }) => {

  return (
    <>
      <Modal show={show} onHide={handleClose} centered dialogClassName="modal-25w">
        <Modal.Body>
          <p className="h3">Call Waiter</p>
          <div className="card-top order-details">
            <p className="h5">Table no {tableno}</p>
            <div style={{display: 'flex'}}>
              <p className="h5">Table #{tableno}</p>
              <p className="h5 ml-3">Asking for: {msg}</p>
            </div>
          </div>
          <span>Request time {reqtime}</span>
          <div className="text-center d-flex justify-content-around mt-3">
            <ModalButton text="Close" type="primary" Close={() => handleClose(id)} />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default CallWaiter;
